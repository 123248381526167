import request from "superagent"
import moment from "moment"
import { allDatesWithinRange } from "./date"

const { GATSBY_GOOGLE_CALENDAR_URL } = process.env

export function getEvents(callback) {
  request.get(GATSBY_GOOGLE_CALENDAR_URL).end((err, resp) => {
    if (err || !resp) return callback([])

    const events = []
    const data = JSON.parse(resp.text)
    if (!data || !data.items || !data.items.length) return callback([])

    data.items.forEach(({ start, end }) => {
      if (moment(start.dateTime).isSame(moment(end.dateTime), "day")) {
        events.push(new Date(start.dateTime))
      } else {
        allDatesWithinRange(start.dateTime, end.dateTime).forEach(date =>
          events.push(date)
        )
      }
    })

    return callback(events)
  })
}
