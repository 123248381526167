import moment from "moment"
import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-datepicker/dist/react-datepicker.css"
import { allDatesWithinRange } from "../util/date"
import { getEvents } from "../util/google-calendar"

const PricingAndAvailabilityPage = ({ data }) => {
  const [blockedEvents, setBlockedEvents] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const html = get(data, "contentfulRentPage.body.childMarkdownRemark.html")

  const isDayBlocked = day => {
    return blockedEvents.find(date => moment(day).isSame(moment(date), "day"))
  }

  const onDatesChange = dates => {
    const [start, end] = dates
    const datesRange = allDatesWithinRange(start, end)
    const daysBlocked = datesRange.map(isDayBlocked).filter(Boolean).length

    if (!daysBlocked) {
      setStartDate(start)
      setEndDate(end)
    } else {
      setStartDate(null)
      setEndDate(null)
      alert("Deze datums zijn niet meer beschikbaar")
    }
  }

  useEffect(() => {
    getEvents(events => setBlockedEvents(events))
  }, [])

  const keywords = `info, prices, pricing, prijzen, prijsaanvraag, request, form, formulier`

  return (
    <>
      {/* Fixed SEO title */}
      <SEO title="Prijsaanvraag" keywords={keywords} />
      <Layout>
        <section>
          <h1>Prijsaanvraag</h1>
          <div
            className="mb-8 html"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <form
            action="/thanks-rent"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="reservation"
            className="w-full max-w-lg"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="reservation" />
            <input
              type="hidden"
              id="checkin"
              name="checkin"
              value={moment(startDate).format("Do MMMM YYYY")}
            />

            <input
              type="hidden"
              id="checkout"
              name="checkout"
              value={moment(endDate).format("Do MMMM YYYY")}
            />

            <div className="flex flex-wrap mb-6 -mx-3">
              {blockedEvents && blockedEvents.length ? (
                <div className="w-full px-3">
                  <span className="label">Aankomst/vertrek</span>
                  <DatePicker
                    selected={startDate}
                    onChange={onDatesChange}
                    startDate={startDate}
                    endDate={endDate}
                    excludeDates={blockedEvents}
                    selectsRange
                    inline
                    required
                    calendarStartDay={1}
                  />
                </div>
              ) : (
                <div className="w-full px-3">
                  <strong>
                    Gelieve in het bericht aan te geven wanneer je wil boeken
                  </strong>
                </div>
              )}
            </div>

            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                <label className="label" htmlFor="firstname">
                  Voornaam
                </label>
                <input
                  className="input"
                  name="firstname"
                  id="firstname"
                  type="text"
                  required
                />
                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
              </div>
              <div className="w-full px-3 md:w-1/2">
                <label className="label" htmlFor="lastname">
                  Achternaam
                </label>
                <input
                  className="input"
                  name="lastname"
                  id="lastname"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <label className="label" htmlFor="email">
                  Email
                </label>
                <input className="input" name="email" id="email" type="email" required />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <label className="label" htmlFor="message">
                  Bericht
                </label>
                <textarea
                  className="textarea"
                  name="message"
                  id="message"
                ></textarea>
              </div>
            </div>

            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <label className="label" htmlFor="amountOfPersons">
                  Aantal personen
                </label>
                <input
                  className="input"
                  type="number"
                  required
                  name="amountOfPersons"
                  id="amountOfPersons"
                ></input>
              </div>
            </div>

            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <label className="label" htmlFor="amountOfChildren">
                  Aantal kinderen tussen 2 en 12 jaar
                </label>
                <input
                  className="input"
                  type="number"
                  name="amountOfChildren"
                  id="amountOfChildren"
                ></input>
              </div>
            </div>

            <div className="mt-10 md:flex md:items-center">
              <div className="md:w-1/3">
                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white rounded shadow bg-primary-700 hover:bg-primary-700 focus:shadow-outline focus:outline-none"
                >
                  Verzenden
                </button>
              </div>
              <div className="md:w-2/3"></div>
            </div>
          </form>
        </section>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query PricingAndAvailabilityPageQuery {
    contentfulRentPage(contentful_id: { eq: "1SDHk8x5KBeLEicrU2E85n" }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PricingAndAvailabilityPage
