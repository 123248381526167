import moment from "moment"

export const allDatesWithinRange = (startDate, endDate) => {
  const dates = []
  const lastDate = moment(endDate).startOf("day")
  let currDate = moment(startDate).startOf("day")

  dates.push(currDate.clone().toDate())
  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate())
  }
  dates.push(currDate.clone().toDate())

  return dates
}
